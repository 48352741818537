export default {
  methods: {
    totalFromItems(items) {
      let totalPrice = 0;

      items.map((el) => {
        if (el.attributes.price_format === 'Default') {
          totalPrice += (el.attributes.total_quantity || el.attributes.quantity) * el.attributes.price;
        }
      });

      return totalPrice;
    },
    tax(val) {
      return (val / 100) * 13;
    },
    total(val) {
      return val + this.tax(val);
    },
    totalPercentage(items) {
      let sum = 0;

      for (let item of items) {
        sum += parseInt(item?.attributes?.percentage) || 0;
      }

      return sum;
    },
    priceFromPercentage(subTotal, percentage) {
      return ((subTotal / 100) * percentage).toFixed(2);
    },
    priceFromPercentageWithHst(subTotal, percentage) {
      return (((subTotal + this.tax(subTotal)) / 100) * percentage).toFixed(2);
    },
  },
};
