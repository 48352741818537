<template>
  <div v-if="projectData && currentProposal" class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <div class="header align-items-stretch">
          <div class="container-fluid d-flex align-items-center">
            <div class="d-flex topbar align-items-center d-lg-none ms-n2 me-3" title="Show aside menu">
              <div class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px">
                <span class="svg-icon svg-icon-1">
                  <inline-svg src="/assets/media/icons/duotune/abstract/abs015.svg" />
                </span>
              </div>
            </div>
            <div class="header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0">
              <router-link :to="{ name: 'dashboard' }">
                <img class="logo-default h-45px" src="/assets/media/rsg-logo.svg" alt="Royal Stone Group Logo" />
              </router-link>
            </div>
            <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
              <div class="d-flex align-items-stretch">
                <div class="header-menu align-items-stretch">
                  <div
                    class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 my-5 my-lg-0 align-items-stretch fw-medium"
                  >
                    <div
                      v-for="item in tabsMenu"
                      :key="item.id"
                      :class="['menu-item me-lg-1', { here: currentScopeType === item.scope_type }]"
                      role="button"
                      @click="() => onCategoryChange(item.scope_type)"
                    >
                      <span class="menu-link py-3">
                        <span class="menu-title">{{ item.title }}</span>
                      </span>
                    </div>
                    <div
                      v-if="projectData.attributes.sharing"
                      :class="['menu-item me-lg-1', { here: currentScopeType === 'Payment' }]"
                      role="button"
                      @click="onPaymentSelected"
                    >
                      <span class="menu-link py-3">
                        <span class="menu-title">Statements</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="currentScopeType !== 'Payment'"
                id="kt_docs_choose_doc_version_wrapper"
                class="position-relative rounded"
                style="background-color: rgba(255, 255, 255, 0.1)"
              >
                <px-select
                  v-if="currentScopeTypeItems && currentScopeTypeItems.length > 1"
                  v-model="currentProposalId"
                  classes="form-select form-select-sm form-select-end form-select-semi-light"
                  placeholder="Select a proposal"
                  :items="currentScopeTypeItems"
                  track-by="id"
                  text-by="attributes.number"
                  sub-text="attributes.updated_at"
                  :sub-text-is-date="true"
                  parent="#kt_docs_choose_doc_version_wrapper"
                  :minimum-results-for-search="Infinity"
                  select2-id="select2_proposals"
                  @input="(id) => onVersionChange(id)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="toolbar py-5 py-lg-7">
          <div class="container-fluid d-flex flex-stack flex-wrap align-items-start">
            <div class="page-title d-flex flex-column me-3">
              <h2 v-if="currentScopeType === 'Payment'" class="h2 text-white">Statements</h2>
              <h2 v-else class="h2 text-white">
                {{ currentProposal.attributes.state || 'Proposal' }}: {{ currentProposal.attributes.scope_type }}
              </h2>
              <p class="fs-6 text-white mb-0">{{ projectData.attributes.address }}</p>
            </div>

            <div class="d-flex pt-4">
              <!--              <button-->
              <!--                  v-if="currentPdfFile"-->
              <!--                  type="button"-->
              <!--                  class="btn btn-sm btn-success btn-flex"-->
              <!--                  @click="() => downloadFile(currentPdfFile)"-->
              <!--              >-->
              <px-btn
                extended-classes="btn-sm btn-custom btn-active-white btn-flex btn-color-white btn-active-color-white"
                :in-process="isPdfInProcess"
                :disabled="isPdfInProcess"
                @click.native="() => downloadFile()"
              >
                <i class="bi bi-cloud-download me-2" />
                Download
              </px-btn>
              <!--              <button v-else type="button" class="btn btn-sm btn-success btn-flex" disabled>-->
              <!--                Manager should generate PDF first-->
              <!--              </button>-->
            </div>
          </div>
        </div>

        <div class="container-fluid pb-10">
          <div class="content flex-row-fluid">
            <div class="card">
              <div class="card-body px-4">
                <div class="row">
                  <div class="d-print-none col-md-auto pt-12">
                    <!--begin::Menu-->
                    <div class="card card-flush d-none d-lg-flex w-lg-300px position-sticky" :style="{ top: '20px' }">
                      <scrollactive
                        class="nav nav-flush menu menu-column menu-title-gray-800 menu-state-primary bg-white rounded d-none d-lg-block py-4"
                        active-class="active"
                        :modify-url="false"
                      >
                        <div v-for="item in sideMenu" :key="item.title" class="menu-item mb-2">
                          <a :href="`#${item.hash}`" class="scrollactive-item menu-link align-items-start p-0">
                            <span class="menu-bullet mt-2">
                              <span class="bullet bullet-dot" />
                            </span>
                            <span class="menu-title">{{ item.title }}</span>
                          </a>
                        </div>
                      </scrollactive>
                    </div>
                    <!--end::Menu-->
                  </div>

                  <div class="col-md">
                    <div v-if="currentScopeType === 'Payment'" class="card shadow-sm">
                      <div class="card-body p-8">
                        <pxs-single-contract-readonly
                          v-for="(contract, index) in contracts"
                          :key="contract.id"
                          :id="convertScopeTypeToId(contract.attributes.scope_type)"
                          :class="[{ 'border-top mt-10 pt-10': index !== 0 }]"
                          :statements-contract="contract.attributes"
                          :statements-change-orders="
                            contract.attributes.change_orders ? contract.attributes.change_orders.data : []
                          "
                          :statements-adjustments="
                            contract.attributes.adjustments ? contract.attributes.adjustments.data : []
                          "
                          :statements-payments="contract.attributes.payments ? contract.attributes.payments.data : []"
                          :scope-type="contract.attributes.scope_type"
                          :contract-type="contract.type"
                        />
                      </div>
                    </div>
                    <div v-else class="card shadow-sm">
                      <div class="card-header d-block bg-light p-8 border-0">
                        <div class="row justify-content-between align-items-center mb-10">
                          <div class="col-md-5 col-xl-4 mb-6 mb-md-0">
                            <img
                              class="img-fluid mh-70px mh-xxxl-125px"
                              :src="currentProposal.attributes.logo"
                              alt="RSG Logo"
                            />
                          </div>

                          <div class="col-md-5 col-xl-3 text-md-end">
                            <h3 v-if="currentProposal" class="fw-bold display-7">
                              {{ scopeNameByParam(currentProposal.attributes.scope_type) }}
                              {{ currentProposal.attributes.state || 'Proposal' }}
                            </h3>
                            <h4 class="fw-bold">
                              {{ currentProposal.attributes.number || "Number isn't set" }}
                            </h4>
                          </div>
                        </div>

                        <div class="row justify-content-between align-items-end">
                          <div class="col-md-5 col-xl-4 mb-6 mb-md-0">
                            <h4 class="fw-bold">Customer</h4>
                            <address class="mb-0">
                              {{ projectData.attributes.first_name }}
                              {{ projectData.attributes.last_name }}
                              <br />
                              {{ projectData.attributes.address_line_1 }}
                              <br />
                              {{
                                separateStringWithComma([projectData.attributes.city, projectData.attributes.province])
                              }}
                              <br />
                              {{ projectData.attributes.phone }}
                              <br />
                              {{ projectData.attributes.email }}
                            </address>
                          </div>

                          <div class="col-md-5 col-xl-3 text-md-end">
                            <div class="d-flex justify-content-between">
                              <span class="me-4">Proposal number:</span>
                              <strong>{{ currentProposal.attributes.number || "Number isn't set" }}</strong>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span class="me-4">Version:</span>
                              <strong class="fw-normal">v{{ currentProposal.attributes.version || 1 }}</strong>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span class="me-4">Date:</span>
                              <strong class="fw-normal">
                                {{ currentProposal.attributes.updated_at | moment('MMMM D, YYYY') }}</strong
                              >
                            </div>
                            <div class="d-flex justify-content-between">
                              <span class="me-4">Project Manager:</span>
                              <strong class="fw-normal">
                                {{ projectData.attributes.manager.data.attributes.first_name }}
                                {{ projectData.attributes.manager.data.attributes.last_name }}
                              </strong>
                            </div>
                            <div class="d-flex justify-content-between">
                              <span class="me-4">Currency:</span> <strong class="fw-normal">CAD</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--begin::Body-->
                      <div class="card-body p-8">
                        <div
                          v-if="
                            currentProposal.attributes.show_introduction &&
                            currentProposal.attributes.state !== 'Change Order'
                          "
                          id="introduction"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Introduction</h2>

                          <div
                            class="format-no-space"
                            v-html="normalizeString(currentProposal.attributes.introduction)"
                          />
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_specifications &&
                            currentProposal.attributes.scope_type === 'Swimming Pool' &&
                            currentProposal.attributes.state !== 'Change Order'
                          "
                          id="specifications"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Specifications</h2>

                          <div v-if="constructions.length && currentProposal.attributes.construction" class="row">
                            <div class="col-sm-6 col-lg-3 mb-6">
                              <div class="card bg-light h-100 lh-sm">
                                <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                                  <img
                                    src="/assets/media/icons/custom/pool.png"
                                    alt="Construction"
                                    width="30"
                                    height="30"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Construction"
                                  />
                                </div>
                                <div
                                  class="card-body p-4"
                                  v-html="
                                    replaceBreakWithBrTag(
                                      currentProposal.attributes.fiberglass_details ||
                                        constructions.find((el) => el.title === currentProposal.attributes.construction)
                                          .description
                                    )
                                  "
                                />
                              </div>
                            </div>

                            <div class="col-sm-6 col-lg-3 mb-6">
                              <div class="card bg-light h-100 lh-sm">
                                <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                                  <img
                                    src="/assets/media/icons/custom/size.png"
                                    alt="Size"
                                    width="30"
                                    height="30"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Size"
                                  />
                                </div>
                                <div class="card-body p-4">
                                  <div class="mb-2">
                                    <h4 class="h5 mb-0">
                                      {{ transformToFtAndIn(currentProposal.attributes.width).ft }} ft.
                                      {{ transformToFtAndIn(currentProposal.attributes.width).in }} in.
                                    </h4>
                                    <div class="text-muted">width</div>
                                  </div>

                                  <div>
                                    <h4 class="h5 mb-0">
                                      {{ transformToFtAndIn(currentProposal.attributes.length).ft }} ft.
                                      {{ transformToFtAndIn(currentProposal.attributes.length).in }} in.
                                    </h4>
                                    <div class="text-muted">length</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-6 col-lg-3 mb-6">
                              <div class="card bg-light h-100 lh-sm">
                                <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                                  <img
                                    src="/assets/media/icons/custom/square.png"
                                    alt="Shape"
                                    width="30"
                                    height="30"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Shape"
                                  />
                                </div>
                                <div class="card-body p-4">
                                  {{ currentProposal.attributes.shape }}
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-6 col-lg-3 mb-6">
                              <div class="card bg-light h-100 lh-sm">
                                <div class="card-header pt-4 px-4 pb-0 min-h-auto">
                                  <img
                                    src="/assets/media/icons/custom/deep.png"
                                    alt="Approx. water depths"
                                    width="30"
                                    height="30"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Approx. water depths"
                                  />
                                </div>
                                <div class="card-body p-4">
                                  <div class="mb-2">
                                    <h4 class="h5 mb-0">
                                      {{ transformToFtAndIn(currentProposal.attributes.shallow).ft }} ft.
                                      {{ transformToFtAndIn(currentProposal.attributes.shallow).in }} in.
                                    </h4>
                                    <div class="text-muted">shallow</div>
                                  </div>

                                  <div>
                                    <h4 class="h5 mb-0">
                                      {{ transformToFtAndIn(currentProposal.attributes.depend).ft }} ft.
                                      {{ transformToFtAndIn(currentProposal.attributes.depend).in }} in.
                                    </h4>
                                    <div class="text-muted">deep</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <p v-html="replaceBreakWithBrTag(currentProposal.attributes.construction_details)" />
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_equipments &&
                            currentProposal.attributes.scope_type === 'Swimming Pool' &&
                            currentProposal.attributes.state !== 'Change Order'
                          "
                          id="equipment"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Standard Equipment</h2>
                          <div
                            v-if="
                              currentProposal.attributes.equipment_items.data.length &&
                              currentProposal.attributes.construction !== 'Renovate'
                            "
                          >
                            <p>
                              Your <span class="text-uppercase">{{ currentProposal.attributes.construction }}</span>
                              AquaSpa pool includes the following:
                            </p>
                            <div class="table-responsive">
                              <!--begin::Table-->
                              <table
                                :class="[
                                  'table mb-0',
                                  { 'table-striped': currentProposal.attributes.equipment_items.data.length },
                                ]"
                              >
                                <!--begin::Thead-->
                                <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                                  <tr class="border-bottom">
                                    <th class="w-200px">Item</th>
                                    <th>Description</th>
                                  </tr>
                                </thead>
                                <!--end::Thead-->
                                <!--begin::Tbody-->
                                <tbody v-if="currentProposal.attributes.equipment_items.data.length">
                                  <tr
                                    v-for="equipmentItem of currentProposal.attributes.equipment_items.data"
                                    :key="equipmentItem.id"
                                  >
                                    <td class="fw-semi-bold align-middle text-lowercase first-letter-capitalize">
                                      {{ equipmentItem.attributes.item }}
                                    </td>
                                    <td>{{ equipmentItem.attributes.description }}</td>
                                  </tr>
                                </tbody>
                                <tbody v-else>
                                  <tr>
                                    <td colspan="2">
                                      <px-placeholder />
                                    </td>
                                  </tr>
                                </tbody>
                                <!--end::Tbody-->
                              </table>
                              <!--end::Table-->
                            </div>
                          </div>
                          <px-placeholder
                            v-else
                            :text="
                              currentProposal.attributes.construction === 'Renovate'
                                ? 'Equipment is not intended for renovation'
                                : 'No data to show'
                            "
                          />
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_pricing &&
                            checkIfHasPricingItems(currentProposal.attributes.pricing_sections.data, 'pricing_items')
                          "
                          id="pricing"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Pricing</h2>
                          <div v-for="section in currentProposal.attributes.pricing_sections.data" :key="section.id">
                            <div v-if="section.attributes.pricing_items.data.length">
                              <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">
                                {{ section.attributes.name || 'Unnamed' }}
                              </h3>
                              <div v-if="section.attributes.pricing_items.data.length" class="mb-12">
                                <div class="table-responsive mb-12">
                                  <!--begin::Table-->
                                  <table class="table table-striped mb-0">
                                    <!--begin::Thead-->
                                    <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                                      <tr class="border-bottom">
                                        <th class="w-200px">Item</th>
                                        <th>Description</th>
                                        <th class="w-50px">Qty</th>
                                        <th class="w-100px">Unit</th>
                                        <th class="w-150px text-end">Price</th>
                                      </tr>
                                    </thead>
                                    <!--end::Thead-->
                                    <!--begin::Tbody-->
                                    <tbody>
                                      <tr
                                        v-for="pricingItem in section.attributes.pricing_items.data"
                                        :key="pricingItem.id"
                                      >
                                        <td class="fw-semi-bold">
                                          {{
                                            pricingItem.attributes.name ||
                                            (pricingItem.attributes.pricing_item_template.data
                                              ? pricingItem.attributes.pricing_item_template.data.attributes.name
                                              : '')
                                          }}
                                        </td>
                                        <td>
                                          <div v-html="replaceBreakWithBrTag(pricingItem.attributes.description)" />
                                          <div
                                            v-if="
                                              pricingItem.attributes.show_client_note && pricingItem.attributes.note
                                            "
                                            class="d-flex notice bg-light-warning rounded p-3 mt-4 align-items-center"
                                          >
                                            <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                                              <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                                            </span>
                                            <span>
                                              {{ pricingItem.attributes.note }}
                                            </span>
                                          </div>
                                          <div
                                            v-if="
                                              pricingItem.attributes.show_internal_note &&
                                              pricingItem.attributes.internal_note &&
                                              section.attributes.show_notes
                                            "
                                            class="d-flex notice bg-light-danger rounded p-3 mt-4 align-items-center"
                                          >
                                            <span class="svg-icon svg-icon-3x svg-icon-danger me-4">
                                              <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                                            </span>
                                            <span>
                                              {{ pricingItem.attributes.internal_note }}
                                            </span>
                                          </div>
                                        </td>
                                        <td>{{ pricingItem.attributes.total_quantity }}</td>
                                        <td>{{ pricingItem.attributes.unit }}</td>
                                        <td class="text-end">
                                          <span v-if="pricingItem.attributes.price_format === 'Default'">
                                            {{
                                              $n(
                                                pricingItem.attributes.total_quantity * pricingItem.attributes.price,
                                                'currency',
                                                'en-CA'
                                              )
                                            }}
                                          </span>
                                          <span v-else>
                                            {{ pricingItem.attributes.price_format }}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr class="bg-light-primary">
                                        <td class="text-primary text-end" colspan="5">
                                          <span class="text-uppercase text-ls fs-9">SubTotal:</span>
                                          <strong class="fw-bold fs-5">
                                            {{
                                              $n(
                                                totalFromItems(section.attributes.pricing_items.data),
                                                'currency',
                                                'en-CA'
                                              )
                                            }}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <!--end::Tbody-->
                                  </table>
                                  <!--end::Table-->
                                </div>

                                <div
                                  v-if="section.attributes.show_important_notice && section.attributes.note"
                                  class="d-flex notice bg-light-warning rounded p-3 mt-4"
                                >
                                  <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                                    <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                                  </span>
                                  <span class="align-self-center">
                                    {{ section.attributes.note }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_plantings &&
                            currentProposal.attributes.scope_type === 'Landscaping' &&
                            checkIfHasPricingItems(currentProposal.attributes.planting_sections.data, 'planting_items')
                          "
                          id="planting"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Planting</h2>
                          <div v-for="section in currentProposal.attributes.planting_sections.data" :key="section.id">
                            <div v-if="section.attributes.planting_items.data.length">
                              <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">
                                {{ section.attributes.name || 'Unnamed' }}
                              </h3>
                              <div v-if="section.attributes.planting_items.data.length" class="mb-12">
                                <div class="table-responsive">
                                  <!--begin::Table-->
                                  <table class="table table-striped mb-0">
                                    <!--begin::Thead-->
                                    <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                                      <tr class="border-bottom">
                                        <th class="w-150px">Category</th>
                                        <th class="w-150px">Item</th>
                                        <th>Description</th>
                                        <th class="w-80px">Size</th>
                                        <!--                          <th class="w-100px">Cost</th>-->
                                        <!--                          <th class="w-50px">X</th>-->
                                        <th class="w-100px">Qty</th>
                                        <th class="w-100px text-end">Price</th>
                                      </tr>
                                    </thead>
                                    <!--end::Thead-->
                                    <!--begin::Tbody-->
                                    <tbody>
                                      <tr
                                        v-for="pricingItem in section.attributes.planting_items.data"
                                        :key="pricingItem.id"
                                      >
                                        <td>
                                          {{ pricingItem.attributes.planting_item_template.data.attributes.category }}
                                        </td>
                                        <td>
                                          <strong>
                                            {{
                                              pricingItem.attributes.planting_item_template.data.attributes.english_name
                                            }}
                                          </strong>
                                          <em class="d-block">
                                            {{
                                              pricingItem.attributes.planting_item_template.data.attributes
                                                .botanical_name
                                            }}
                                          </em>
                                        </td>
                                        <td>
                                          <div v-html="replaceBreakWithBrTag(pricingItem.attributes.description)" />
                                          <div class="row align-items-center small py-2 gx-4">
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes.full_sun
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon">
                                                <inline-svg src="/assets/media/icons/custom/full-sun.svg" />
                                              </span>
                                            </div>
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes
                                                  .partial_sun
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon">
                                                <inline-svg src="/assets/media/icons/custom/partial-sun.svg" />
                                              </span>
                                            </div>
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes.full_shade
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon">
                                                <inline-svg src="/assets/media/icons/custom/full-shade.svg" />
                                              </span>
                                            </div>
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes.native
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon">
                                                <inline-svg
                                                  src="/assets/media/icons/custom/native.svg"
                                                  stroke="#a1a5b7"
                                                />
                                              </span>
                                            </div>
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes
                                                  .attracts_birds
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon">
                                                <inline-svg
                                                  src="/assets/media/icons/custom/attractive-birds.svg"
                                                  stroke="#a1a5b7"
                                                />
                                              </span>
                                            </div>

                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes.height
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon me-1">
                                                <inline-svg src="/assets/media/icons/custom/height.svg" />
                                              </span>
                                              {{ pricingItem.attributes.planting_item_template.data.attributes.height }}
                                            </div>
                                            <div
                                              v-if="pricingItem.attributes.planting_item_template.data.attributes.bloom"
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon me-1">
                                                <inline-svg src="/assets/media/icons/custom/bloom-color.svg" />
                                              </span>
                                              {{ pricingItem.attributes.planting_item_template.data.attributes.bloom }}
                                            </div>
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes.spread
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon me-1">
                                                <inline-svg src="/assets/media/icons/custom/spread.svg" />
                                              </span>
                                              {{ pricingItem.attributes.planting_item_template.data.attributes.spread }}
                                            </div>
                                            <div
                                              v-if="
                                                pricingItem.attributes.planting_item_template.data.attributes.foliage
                                              "
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon me-1">
                                                <inline-svg src="/assets/media/icons/custom/foliage-value.svg" />
                                              </span>
                                              {{
                                                pricingItem.attributes.planting_item_template.data.attributes.foliage
                                              }}
                                            </div>
                                            <div
                                              v-if="pricingItem.attributes.planting_item_template.data.attributes.shape"
                                              class="col-auto text-nowrap mb-2"
                                            >
                                              <span class="svg-icon me-1">
                                                <inline-svg src="/assets/media/icons/custom/shape.svg" />
                                              </span>
                                              {{ pricingItem.attributes.planting_item_template.data.attributes.shape }}
                                            </div>
                                          </div>
                                          <div
                                            v-if="
                                              pricingItem.attributes.show_client_note && pricingItem.attributes.note
                                            "
                                            class="d-flex notice bg-light-warning rounded p-3 mt-4 align-items-center"
                                          >
                                            <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                                              <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                                            </span>
                                            <span>
                                              {{
                                                pricingItem.attributes.note ||
                                                (pricingItem.attributes.planting_item_template.data
                                                  ? pricingItem.attributes.planting_item_template.data.attributes.note
                                                  : '')
                                              }}
                                            </span>
                                          </div>
                                          <div
                                            v-if="
                                              pricingItem.attributes.show_internal_note &&
                                              pricingItem.attributes.internal_note
                                            "
                                            class="d-flex notice bg-light-danger rounded p-3 mt-4 align-items-center"
                                          >
                                            <span class="svg-icon svg-icon-3x svg-icon-danger me-4">
                                              <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                                            </span>
                                            <span class="align-self-center">
                                              {{
                                                pricingItem.attributes.internal_note ||
                                                pricingItem.attributes.planting_item_template.data.attributes
                                                  .internal_note
                                              }}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          {{
                                            pricingItem.attributes.size ||
                                            `${
                                              pricingItem.attributes.planting_item_template.data.attributes.size_digit
                                            } ${
                                              pricingItem.attributes.planting_item_template.data.attributes.size_unit ||
                                              'cm'
                                            }`
                                          }}
                                        </td>
                                        <!--                          <td>-->
                                        <!--                            {{ pricingItem.attributes.cost }}-->
                                        <!--                          </td>-->
                                        <!--                          <td>-->
                                        <!--                            {{ pricingItem.attributes.multiplier || 1 }}-->
                                        <!--                          </td>-->
                                        <td>{{ pricingItem.attributes.quantity }}</td>
                                        <td class="text-end">
                                          <span v-if="pricingItem.attributes.price_format === 'Default'">
                                            {{
                                              $n(
                                                pricingItem.attributes.quantity * pricingItem.attributes.price,
                                                'currency',
                                                'en-CA'
                                              )
                                            }}
                                          </span>
                                          <span v-else>
                                            {{ pricingItem.attributes.price_format }}
                                          </span>
                                        </td>
                                      </tr>
                                      <tr class="bg-light-primary">
                                        <td class="text-primary text-end" colspan="6">
                                          <span class="text-uppercase text-ls fs-9">SubTotal:</span>
                                          <strong class="fw-bold fs-5">
                                            {{
                                              $n(
                                                totalFromItems(section.attributes.planting_items.data),
                                                'currency',
                                                'en-CA'
                                              )
                                            }}
                                          </strong>
                                        </td>
                                      </tr>
                                    </tbody>
                                    <!--end::Tbody-->
                                  </table>
                                  <!--end::Table-->
                                </div>

                                <div
                                  v-if="section.attributes.show_important_notice && section.attributes.note"
                                  class="d-flex notice bg-light-warning rounded p-3 mt-4"
                                >
                                  <span class="svg-icon svg-icon-3x svg-icon-warning me-4">
                                    <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                                  </span>
                                  <span class="align-self-center">
                                    {{ section.attributes.note }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_special_notes && currentProposal.attributes.special_notes
                          "
                          id="notes"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Notes</h2>
                          <div v-html="normalizeString(currentProposal.attributes.special_notes)" />
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_inclusions &&
                            currentProposal.attributes.state !== 'Change Order'
                          "
                          id="considerations"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Considerations</h2>
                          <div class="table-responsive">
                            <!--begin::Table-->
                            <table
                              :class="[
                                'table mb-0',
                                { 'table-striped': currentProposal.attributes.inclusions.data.length },
                              ]"
                            >
                              <!--begin::Thead-->
                              <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                                <tr class="border-bottom">
                                  <th class="w-200px">Item</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <!--end::Thead-->
                              <!--begin::Tbody-->
                              <tbody v-if="currentProposal.attributes.inclusions.data.length">
                                <tr v-for="inclusion in currentProposal.attributes.inclusions.data" :key="inclusion.id">
                                  <td class="fw-bold align-middle">{{ inclusion.attributes.name }}</td>
                                  <td>{{ inclusion.attributes.description }}</td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td colspan="2">
                                    <px-placeholder />
                                  </td>
                                </tr>
                              </tbody>
                              <!--end::Tbody-->
                            </table>
                            <!--end::Table-->
                          </div>
                        </div>
                        <div v-if="currentProposal.attributes.show_payment_details" id="payment-details" class="mb-14">
                          <h2 class="fw-bold mb-4">Payment Details</h2>
                          <div class="row mb-6 justify-content-between">
                            <div class="col-md-5 col-xl-4 mb-6">
                              <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Total Summary</h3>
                              <div class="d-flex align-items-center justify-content-between mb-4">
                                <div class="me-4">Subtotal</div>
                                <div>
                                  {{ $n(currentProposal.attributes.sub_total || 0, 'currency', 'en-CA') }}
                                </div>
                              </div>
                              <div class="d-flex align-items-center justify-content-between mb-4">
                                <div class="me-4">HST 13%</div>
                                <div>
                                  {{
                                    $n(tax(currentProposal.attributes.sub_total || 0).toFixed(2), 'currency', 'en-CA')
                                  }}
                                </div>
                              </div>
                              <div class="d-flex align-items-center justify-content-between fw-bold mb-4">
                                <span class="text-uppercase text-ls text-dark fs-9">Total:</span>
                                <strong class="fw-bold fs-5">
                                  {{
                                    $n(total(currentProposal.attributes.sub_total || 0).toFixed(2), 'currency', 'en-CA')
                                  }}
                                </strong>
                              </div>
                            </div>

                            <div class="col-md-6 mb-6">
                              <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Payment Schedule</h3>
                              <div
                                v-if="currentProposal.attributes.payment_schedule_items.data.length"
                                class="table-responsive"
                              >
                                <!--begin::Table-->
                                <table class="table">
                                  <!--begin::Thead-->
                                  <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                                    <tr>
                                      <th class="w-50px ps-0">Pct(%)</th>
                                      <th>Milestone</th>
                                      <th class="w-100px text-end">Amount($)</th>
                                    </tr>
                                  </thead>
                                  <!--end::Thead-->
                                  <!--begin::Tbody-->
                                  <tbody>
                                    <tr
                                      v-for="schedule in currentProposal.attributes.payment_schedule_items.data"
                                      :key="schedule.id"
                                    >
                                      <td class="ps-0">{{ schedule.attributes.percentage }}%</td>
                                      <td>{{ schedule.attributes.note }}</td>
                                      <td class="text-end">
                                        {{
                                          $n(
                                            priceFromPercentageWithHst(
                                              currentProposal.attributes.sub_total || 0,
                                              schedule.attributes.percentage
                                            ),
                                            'currency',
                                            'en-CA'
                                          )
                                        }}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <!--end::Tbody-->
                                </table>
                                <!--end::Table-->
                              </div>
                              <px-placeholder v-else />
                            </div>
                          </div>

                          <div class="bg-light rounded px-4 py-6 mb-8">
                            <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">
                              Pricing & Payment Information
                            </h3>
                            <div
                              class="format-no-space"
                              v-html="normalizeString(currentProposal.attributes.pricing_and_payment_information)"
                            />
                          </div>

                          <h3 class="fs-7 fw-bold text-ls-sm text-uppercase text-primary mb-4">Acceptance</h3>
                          <p>
                            I hereby acknowledge that I have read this Proposal, understand and agree to its terms and
                            conditions herein in their entirety, and accept that this document becomes my binding
                            contract with Royal Stone.
                          </p>
                        </div>
                        <div
                          v-if="
                            currentProposal.attributes.show_terms &&
                            currentProposal.attributes.terms_conditions_warranties !== emptyTermsAndConditions &&
                            currentProposal.attributes.terms_conditions_warranties
                          "
                          id="terms"
                          class="mb-14"
                        >
                          <h2 class="fw-bold mb-4">Terms, Conditions, and Warranties</h2>
                          <div
                            class="scroll mh-400px py-4 px-2 border rounded"
                            v-html="normalizeString(currentProposal.attributes.terms_conditions_warranties)"
                          />
                        </div>
                      </div>
                      <!--end: Card Body-->
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Card Body-->
            </div>
            <!--end::Card-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Page-->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProjectPreviewMixin from '@/mixins/ProjectPreviewMixin';

import PxsSingleContractReadonly from '@/views/projects/views/sections/PxsSingleContractReadonly';

export default {
  mixins: [ProjectPreviewMixin],
  components: { PxsSingleContractReadonly },
  data() {
    return {
      sideMenu: [],
      currentSideMenuItem: null,
      currentProposalId: null,
      currentPdfFile: null,

      secret: null,
      code: null,

      isPdfInProcess: false,

      emptyTermsAndConditions: '"<p><br></p>"',
    };
  },
  computed: {
    ...mapGetters({
      pdfFile: 'proposalWizard/pdfFile',
      tabsMenu: 'projectPreview/tabsMenu',
      projectData: 'projectPreview/projectData',
      paymentData: 'projectPreview/paymentData',
      currentProposal: 'projectPreview/currentProposal',
      currentScopeTypeItems: 'projectPreview/currentScopeTypeItems',
      currentScopeType: 'projectPreview/currentScopeType',
    }),
    contracts() {
      const preparedContracts = [];

      this.paymentData.attributes.proposal_scopes.data.map((el) => {
        if (el.attributes.status === 'Signed' && el.attributes.current === true) {
          preparedContracts.push(el);
        }
      });

      return preparedContracts;
    },
    constructions() {
      return [
        { title: 'Vinyl', description: 'Vinyl lined swimming pool with steel walls and a 32MPa concrete floor' },
        {
          title: 'Concrete',
          description: 'Concrete pool with 12" walls and 12" floor, reinforced with 15mm steel rebar',
        },
        {
          title: 'Fiberglass',
          description: 'Fiberglass shell composed of interwoven glass threads covered with a polyester resin',
        },
        { title: 'Renovate', description: 'Pool renovation as per the specifications described in this proposal' },
      ];
    },
  },
  async mounted() {
    this.currentProposalId = this.currentProposal.id;
    this.currentPdfFile = this.projectData.attributes.proposal_scopes.data.find(
      (el) => el.id === this.currentProposalId
    ).attributes.pdf_file;
    this.secret = this.projectData.attributes.secret;
    this.code = this.projectData.attributes.code;

    this.setSideMenu();
  },
  methods: {
    ...mapActions({
      actionChangeCurrentScopeTypeItems: 'projectPreview/changeCurrentScopeTypeItems',
      actionProposalWizardMakePdf: 'projectPreview/makePdf',
    }),
    ...mapMutations({
      setCurrentScopeType: 'projectPreview/SET_CURRENT_SCOPE_TYPE',
      setCurrentProposal: 'projectPreview/SET_CURRENT_PROPOSAL',
      setPdfFile: 'projectPreview/SET_PDF_FILE',
      setPdfReady: 'projectPreview/SET_PDF_READY',
    }),
    checkIfHasPricingItems(items, fieldName) {
      return !!items.find((el) => el.attributes[fieldName]?.data?.length > 0);
    },
    printContract() {
      window.print();
    },
    convertScopeTypeToId(type) {
      switch (type) {
        case 'Swimming Pool':
          return 'swimming-pool';
        case 'Woodworking':
          return 'woodworking';
        case 'Landscaping':
          return 'landscaping';
        default:
          return null;
      }
    },
    setSideMenu() {
      const whichParameterUse =
        this.currentScopeType === 'Payment' ? this.currentScopeType : this.currentProposal.attributes.scope_type;
      const preparedSideMenu = [];

      switch (whichParameterUse) {
        case 'Swimming Pool':
          if (
            this.currentProposal.attributes.show_introduction &&
            this.currentProposal.attributes.state !== 'Change Order'
          ) {
            preparedSideMenu.push({ title: 'Introduction', hash: 'introduction' });
          }
          if (this.currentProposal.attributes.show_specifications) {
            preparedSideMenu.push({ title: 'Specification', hash: 'specifications' });
          }
          if (this.currentProposal.attributes.show_equipments) {
            preparedSideMenu.push({ title: 'Equipment', hash: 'equipment' });
          }
          if (this.currentProposal.attributes.show_pricing) {
            preparedSideMenu.push({ title: 'Pricing', hash: 'pricing' });
          }
          if (this.currentProposal.attributes.show_special_notes) {
            preparedSideMenu.push({ title: 'Notes', hash: 'notes' });
          }
          if (
            this.currentProposal.attributes.show_inclusions &&
            this.currentProposal.attributes.state !== 'Change Order'
          ) {
            preparedSideMenu.push({ title: 'Considerations', hash: 'considerations' });
          }
          if (this.currentProposal.attributes.show_payment_details) {
            preparedSideMenu.push({ title: 'Payment Details', hash: 'payment-details' });
          }
          if (this.currentProposal.attributes.show_terms) {
            preparedSideMenu.push({ title: 'Terms, Conditions, and Warranties', hash: 'terms' });
          }

          this.sideMenu = preparedSideMenu;
          break;
        case 'Woodworking':
          if (
            this.currentProposal.attributes.show_introduction &&
            this.currentProposal.attributes.state !== 'Change Order'
          ) {
            preparedSideMenu.push({ title: 'Introduction', hash: 'introduction' });
          }
          if (this.currentProposal.attributes.show_pricing) {
            preparedSideMenu.push({ title: 'Pricing', hash: 'pricing' });
          }
          if (this.currentProposal.attributes.show_special_notes) {
            preparedSideMenu.push({ title: 'Notes', hash: 'notes' });
          }
          if (
            this.currentProposal.attributes.show_inclusions &&
            this.currentProposal.attributes.state !== 'Change Order'
          ) {
            preparedSideMenu.push({ title: 'Considerations', hash: 'considerations' });
          }
          if (this.currentProposal.attributes.show_payment_details) {
            preparedSideMenu.push({ title: 'Payment Details', hash: 'payment-details' });
          }
          if (this.currentProposal.attributes.show_terms) {
            preparedSideMenu.push({ title: 'Terms, Conditions, and Warranties', hash: 'terms' });
          }

          this.sideMenu = preparedSideMenu;
          break;
        case 'Landscaping':
          if (
            this.currentProposal.attributes.show_introduction &&
            this.currentProposal.attributes.state !== 'Change Order'
          ) {
            preparedSideMenu.push({ title: 'Introduction', hash: 'introduction' });
          }
          if (this.currentProposal.attributes.show_pricing) {
            preparedSideMenu.push({ title: 'Pricing', hash: 'pricing' });
          }
          if (this.currentProposal.attributes.show_plantings) {
            preparedSideMenu.push({ title: 'Planting', hash: 'planting' });
          }
          if (this.currentProposal.attributes.show_special_notes) {
            preparedSideMenu.push({ title: 'Notes', hash: 'notes' });
          }
          if (
            this.currentProposal.attributes.show_inclusions &&
            this.currentProposal.attributes.state !== 'Change Order'
          ) {
            preparedSideMenu.push({ title: 'Considerations', hash: 'considerations' });
          }
          if (this.currentProposal.attributes.show_payment_details) {
            preparedSideMenu.push({ title: 'Payment Details', hash: 'payment-details' });
          }
          if (this.currentProposal.attributes.show_terms) {
            preparedSideMenu.push({ title: 'Terms, Conditions, and Warranties', hash: 'terms' });
          }

          this.sideMenu = preparedSideMenu;
          break;
        case 'Payment':
          if (
            this.paymentData.attributes.proposal_scopes.data.find((el) => el.attributes.scope_type === 'Landscaping')
          ) {
            preparedSideMenu.push({
              title: 'Landscaping',
              hash: 'landscaping',
            });
          }
          if (
            this.paymentData.attributes.proposal_scopes.data.find((el) => el.attributes.scope_type === 'Swimming Pool')
          ) {
            preparedSideMenu.push({
              title: 'Pool',
              hash: 'swimming-pool',
            });
          }
          if (
            this.paymentData.attributes.proposal_scopes.data.find((el) => el.attributes.scope_type === 'Woodworking')
          ) {
            preparedSideMenu.push({
              title: 'Woodwork',
              hash: 'woodworking',
            });
          }

          this.sideMenu = preparedSideMenu;
          break;
        default:
          break;
      }

      this.currentSideMenuItem = this.sideMenu[0].hash;
    },
    onCategoryChange(scopeType) {
      if (this.currentScopeType === scopeType) return false;

      this.setCurrentScopeType(scopeType);
      this.actionChangeCurrentScopeTypeItems(scopeType);
      this.setCurrentProposal(this.currentScopeTypeItems[0]);

      this.currentProposalId = this.currentProposal.id;
      this.currentPdfFile = this.projectData.attributes.proposal_scopes.data.find(
        (el) => el.id === this.currentProposalId
      ).attributes.pdf_file;
      this.setSideMenu();
    },
    onPaymentSelected() {
      this.setCurrentScopeType('Payment');
      this.setSideMenu();
    },
    onVersionChange(id) {
      this.setCurrentProposal(this.currentScopeTypeItems.find((el) => el.id === id));

      this.currentProposalId = this.currentProposal.id;
      this.currentPdfFile = this.projectData.attributes.proposal_scopes.data.find(
        (el) => el.id === this.currentProposalId
      ).attributes.pdf_file;

      this.setSideMenu();
    },
    async makePdf() {
      this.isPdfInProcess = true;

      const newPdf = await this.actionProposalWizardMakePdf(this.currentProposalId);

      this.currentPdfFile = await newPdf.data.pdf_file;

      this.isPdfInProcess = false;
    },
    async downloadFile() {
      if (!this.currentPdfFile) {
        await this.makePdf();
      }

      await this.downloadPdfFromTab(this.currentPdfFile, 'proposal');
    },
  },
};
</script>

<style lang="scss">
#kt_body {
  background-image: url('/assets/media/header-bg.png');
}
</style>
