<template>
  <div v-if="contract && contractType !== 'empty_proposal_scope'">
    <h2 class="h3 fw-bold mb-4">{{ scopeType }}</h2>
    <div class="row">
      <div class="col-lg-8 mb-4 mb-lg-0">
        <div class="p-4 py-lg-8 px-lg-12 border-dashed-long rounded mb-11">
          <div class="row justify-content-between">
            <div class="col-lg-5 mb-4 mb-lg-0">
              <div class="mb-8">
                <div class="text-uppercase text-ls text-muted fs-9">Contract:</div>
                <strong>
                  {{ contract.number }}
                  v{{ contract.version }}
                </strong>
              </div>

              <div>
                <div class="text-uppercase text-ls text-muted fs-9">Last Payment:</div>
                <strong v-if="contract.last_payment && contract.last_payment.date">
                  {{ contract.last_payment.date | moment('DD MMM, YYYY') }}
                </strong>
                <strong v-else>No payments yet :(</strong>
              </div>
            </div>

            <div class="col-lg-6 text-lg-end">
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Original contract subtotal:</div>
                <div class="w-175px">{{ $n(contract.sub_total, 'currency', 'en-CA') }}</div>
              </div>
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Total change order subtotal:</div>
                <div class="w-175px">
                  {{ $n(contract.change_orders_sum, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between fw-semi-bold mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">Full Project:</div>
                <div class="w-175px">
                  {{ $n(contract.full_sum, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between mb-6 mb-lg-3">
                <div class="w-300px mb-1 mb-lg-0">HST:</div>
                <div class="w-175px">
                  {{ $n(contract.hst, 'currency', 'en-CA') }}
                </div>
              </div>
              <div class="d-flex justify-content-between fw-semi-bold">
                <div class="w-300px mb-1 mb-lg-0">Total:</div>
                <div class="w-175px">
                  {{ $n(contract.total, 'currency', 'en-CA') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="['mb-4', { 'd-print-none': changeOrders && changeOrders.length }]">
          <h4 class="h5 fw-bold mb-4">Change Orders</h4>
          <div class="position-relative">
            <px-loader v-if="!changeOrders" :is-full-window="false" bg="blue" />
            <div v-if="changeOrders && changeOrders.length" class="table-responsive mx-n3">
              <table class="table table-hover table-row-bordered table-row-dashed align-middle">
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm align-middle">
                  <tr>
                    <th class="pb-2">Description</th>
                    <th class="pb-2">ID</th>
                    <th class="w-200px pb-2">Date</th>
                    <th class="w-130px text-end pb-2">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="changeOrdersItem in changeOrders" :key="changeOrdersItem.id">
                    <td>
                      {{ changeOrdersItem.attributes.construction_details || 'No description' }}
                    </td>
                    <td>
                      {{ changeOrdersItem.attributes.number }}
                      v{{ changeOrdersItem.attributes.version }}
                    </td>
                    <td>
                      {{ changeOrdersItem.attributes.updated_at | moment('YYYY.MM.DD') }}
                    </td>
                    <td class="text-end">
                      {{ $n(changeOrdersItem.attributes.sub_total, 'currency', 'en-CA') }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-semi-bold">
                    <td colspan="2" />
                    <td>Subtotal of change orders:</td>
                    <td class="text-end">
                      {{ $n(totalByItems(changeOrders, 'sub_total'), 'currency', 'en-CA') }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <px-placeholder v-else />
          </div>
        </div>

        <div :class="['mb-4', { 'd-print-none': adjustments && adjustments.length }]">
          <h4 class="h5 fw-bold mb-4">Adjustments</h4>
          <div class="position-relative">
            <px-loader v-if="!adjustments" :is-full-window="false" bg="blue" />
            <div v-if="adjustments && adjustments.length" class="table-responsive mx-n3">
              <table class="table table-hover table-row-bordered table-row-dashed align-middle">
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm align-middle">
                  <tr>
                    <th class="pb-2">Description</th>
                    <th class="w-200px pb-2">Date</th>
                    <th class="w-125px text-end pb-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="adjustmentsItem in adjustments" :key="adjustmentsItem.id">
                    <td>
                      {{ adjustmentsItem.attributes.description || 'No description' }}
                    </td>
                    <td>
                      {{ adjustmentsItem.attributes.date | moment('YYYY.MM.DD') }}
                    </td>
                    <td class="text-end">
                      {{ $n(adjustmentsItem.attributes.amount, 'currency', 'en-CA') }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-semi-bold">
                    <td />
                    <td>Subtotal of adjustments:</td>
                    <td class="text-end">
                      {{ $n(totalByItems(adjustments, 'amount'), 'currency', 'en-CA') }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <px-placeholder v-else />
          </div>
        </div>

        <div :class="['mb-6', { 'd-print-none': payments && payments.length }]">
          <h4 class="h5 fw-bold mb-4">Payments</h4>
          <div class="position-relative">
            <px-loader v-if="!payments" :is-full-window="false" bg="blue" />
            <div v-if="payments && payments.length" class="table-responsive mx-n3">
              <table class="table table-hover table-row-bordered table-row-dashed align-middle">
                <thead class="fs-10 fw-bold text-uppercase text-ls-sm align-middle">
                  <tr>
                    <th class="pb-2">Date</th>
                    <th class="w-125px text-end pb-2">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="paymentsItem in payments" :key="paymentsItem.id">
                    <td>
                      <div class="d-flex align-items-center">
                        <i
                          :class="[
                            'fa fa-genderless fs-2 me-2',
                            { 'text-danger': !paymentsItem.attributes.cash },
                            { 'text-success': paymentsItem.attributes.cash },
                          ]"
                        />
                        {{ paymentsItem.attributes.date | moment('YYYY.MM.DD') }}
                      </div>
                    </td>
                    <td class="text-end">
                      {{ $n(paymentsItem.attributes.amount, 'currency', 'en-CA') }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="fw-semi-bold">
                    <td class="text-end">Subtotal of payments:</td>
                    <td class="text-end">
                      {{ $n(totalByItems(payments, 'amount'), 'currency', 'en-CA') }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <px-placeholder v-else />
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-lg-6 text-lg-end">
            <div class="d-flex justify-content-between mb-6 mb-lg-3">
              <div class="w-300px mb-1 mb-lg-0">HST Received:</div>
              <div class="w-175px">
                {{ $n(contract.hst_received, 'currency', 'en-CA') }}
              </div>
            </div>
            <div class="d-flex justify-content-between mb-6 mb-lg-3">
              <div class="w-300px mb-1 mb-lg-0">Amount Outstanding:</div>
              <div class="w-175px">
                {{ $n(contract.outstanding, 'currency', 'en-CA') }}
              </div>
            </div>
            <div class="d-flex justify-content-between fw-semi-bold mb-6 mb-lg-3">
              <div class="w-300px mb-1 mb-lg-0">Outstanding with HST:</div>
              <div class="w-175px">
                {{ $n(contract.outstanding_with_hst, 'currency', 'en-CA') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="bg-light rounded p-4 p-lg-8 mb-12">
          <h3 class="text-uppercase text-ls-sm fs-7 fw-bold text-gray-600 mb-4">Project Invoice</h3>
          <div class="mb-5">
            <div class="text-uppercase text-ls text-muted fs-9">Project Balance:</div>
            <strong class="fw-semi-bold">{{ $n(contract.full_sum, 'currency', 'en-CA') }} +HST</strong>
          </div>

          <div class="mb-5">
            <div class="text-uppercase text-ls text-danger fs-9">Due Immediately:</div>
            <strong class="fw-bold text-danger">
              <span v-if="contract.payment_due_amount">
                {{ $n(contract.payment_due_amount, 'currency', 'en-CA') }}
              </span>
              <span v-else>No yet...</span>
            </strong>
          </div>
          <div class="mb-5">
            <div class="text-uppercase text-ls text-muted fs-9">Notes:</div>
            <strong class="fw-semi-bold">
              <span v-if="contract.payment_due_note">
                {{ contract.payment_due_note }}
              </span>
              <span v-else>No yet...</span>
            </strong>
          </div>

          <br />

          <h3 class="text-uppercase text-ls-sm fs-7 fw-bold text-gray-600 mb-4">Payment Details</h3>
          <div class="mb-5">
            <div class="text-uppercase text-ls text-muted fs-9">Cheque:</div>
            <strong class="fw-semi-bold">Royal Stone Landscaping & Design</strong>
          </div>
          <div>
            <div class="text-uppercase text-ls text-muted fs-9">Online Banking:</div>
            <strong class="fw-semi-bold">Ask your project manager</strong>
          </div>
        </div>

        <div class="px-4 px-lg-8">
          <h3 class="text-uppercase text-ls-sm fs-7 fw-bold text-gray-600 mb-4">Payment Schedule</h3>
          <div
            v-for="(paymentScheduleItem, index) in contract.payment_schedule_items.data"
            :key="paymentScheduleItem.id"
            :class="['d-flex', { 'mt-5': index !== 0 }]"
          >
            <div class="fw-semi-bold w-50px me-2">{{ paymentScheduleItem.attributes.percentage }}%</div>
            <div>{{ paymentScheduleItem.attributes.note }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scopeType: {
      type: String,
      required: true,
    },
    contractType: {
      type: String,
      required: true,
    },
    statementsContract: {
      type: Object,
      required: true,
    },
    statementsChangeOrders: {
      type: Array,
      required: true,
    },
    statementsAdjustments: {
      type: Array,
      required: true,
    },
    statementsPayments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    contract() {
      const preparedContract = this.statementsContract ? { ...this.statementsContract } : [];
      if (this.statementsContract) {
        delete preparedContract.payments;
        delete preparedContract.adjustments;
        delete preparedContract.scope_type;
      }

      return preparedContract || null;
    },
    changeOrders() {
      return this.statementsChangeOrders || null;
    },
    adjustments() {
      return this.statementsAdjustments || null;
    },
    payments() {
      return this.statementsPayments || null;
    },
  },
  methods: {
    totalByItems(items, fieldName) {
      let totalPrice = 0;

      if (items.length) {
        items.map((el) => {
          totalPrice += +el.attributes[fieldName];
        });
      }

      return totalPrice;
    },
  },
};
</script>
